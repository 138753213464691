import 'es6-promise/auto'
import 'url-search-params-polyfill'
import fetch from 'fetch-ie8'
import { Storage } from './lib'
import env from './env'
import { NormalizedDeviceId } from './helpers'

import('./const').then(({ PLATFORM_NAME }) => {
  import(`./platform/${PLATFORM_NAME}`).then(async (Platform) => {
    if (!window.fetch) {
      console.log('Add `fetch` polyfill')
      window.fetch = fetch
    }

    console.log(`User Agent: ${navigator.userAgent}`)
    console.log(`Loading ${PLATFORM_NAME}...`)

    const deviceSettings = await Platform.Load()
    // -> no deviceSettings
    if (localStorage.deviceSettings === undefined) {
      Storage.set('deviceSettings', { ...deviceSettings, deviceId: NormalizedDeviceId(deviceSettings.deviceId) })
    }

    env.Platform = Platform

    // eslint-disable-next-line no-unused-expressions
    import('./boot')
  })
})
