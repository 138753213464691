import { Get } from '../lib'

const path = 'List/MyPurchases'

export default async function () {
  try {
    const result = await Get(path)
    return result.myList || {}
  } catch (e) {
    throw e
  }
}
