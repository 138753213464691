import { Get, Storage } from '../lib'

const path = 'Site/GetMenu'

export default async function () {
  try {
    const params = `plan=${Storage.get('login').token.userPackage}&kbotoken=${Storage.get('login').kboToken}`
    const result = await Get(`${path}?${params}`)
    return (
      result.menuSectionModel.menuPanelModel &&
      result.menuSectionModel.menuPanelModel.filter((item) => (process.env.REACT_APP_HIDE_LIVE === 'true' ? item.menuCategoryName !== 'Live' : item))
    )
  } catch (e) {
    throw e
  }
}
