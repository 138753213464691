function shorten(string, limit, separator = ' ') {
  return string.substr(0, string.lastIndexOf(separator, limit))
}

export default (string, limit = 450) => {
  if (typeof string !== 'string') {
    return string
  }

  if (string.length <= limit) {
    return string
  }

  return `${shorten(string, limit)}${'\u2026'}`
}
