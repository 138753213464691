import { Get } from '../lib'

const path = 'List/ContinueWatching'

export default async function() {
  try {
    const result = await Get(path)
    return result.featureSection
  } catch (e) {
    throw e
  }
}
