import { Get } from '../lib'

const path = 'Show/Details'

export default async function (id) {
  try {
    const result = await Get(`${path}/${id}`)
    return result.show
  } catch (e) {
    throw e
  }
}
