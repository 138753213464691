const set = (dom) => dom.focus({ preventScroll: true })

let counter = 0

export const GetFirstItemOnScreen = () => {
  return document.querySelector('button:not([tabindex="-1"])')
}

export const GetLastFocusedItem = () => {
  try {
    const path = `${window.routerHistory.location.pathname}${window.routerHistory.location.search}`
    return document.getElementById(window.footprint.history[path][0])
  } catch (_) {
    return null
  }
}

export const Focus = (dom) => {
  counter++
  try {
    const history = GetLastFocusedItem()
    const first = GetFirstItemOnScreen()
    const next = dom || history || first

    if (!next) {
      console.log(`Skip focus [${counter}]`)
      return
    }

    console.log(`Focusing on #${next.id} [${counter}]`)

    return set(next)
  } catch (_) {}
}
