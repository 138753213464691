import { Storage } from '../lib'

export default (categoryId, episodeId) => {
  // Stores the last watched show episode to local storage
  let lastWatchedEpisodes = Storage.get('lastWatchedEpisodes')
  if (!Array.isArray(lastWatchedEpisodes)) {
    lastWatchedEpisodes = []
  }

  // remove prev
  lastWatchedEpisodes = lastWatchedEpisodes.filter((e) => e.categoryId !== categoryId)

  Storage.set('lastWatchedEpisodes', [...lastWatchedEpisodes, { categoryId, episodeId }].slice(-1 * 10))
}
