import { Get, Storage } from '../lib'

const path = 'Media/GetDetails'

export default async function (id, pv = false) {
  try {
    const result = await Get(`${path}/${id}?devicetype=${Storage.get('deviceSettings').deviceType}${pv ? '&pv=true' : ''}`, {
      ipx: Storage.get('geoData').ip,
    })

    return result
  } catch (e) {
    throw e
  }
}
