import { Post, Storage } from '../lib'

const path = 'Media/Log'

export default async function ({
  behaviorType,
  categoryId,
  categoryName,
  showType,
  assetId,
  time,
  length,
  userPackage,
  userType,
  lastPosition,
  assetType,
  episodeId,
  dateAired,
}) {
  try {
    let mediaLogData = {
      categoryId,
      categoryName,
      assetId,
      userId: Storage.get('login').userId,
      email: Storage.get('login').token.email,
      length,
      userAgent: window.navigator.userAgent,
      deviceType: Storage.get('deviceSettings').deviceType,
      ipAdress: Storage.get('geoData').ip,
      countyCode: Storage.get('geoData').countyCode,
      behaviorType,
      showType,
      assetType,
      print: Storage.get('deviceSettings').deviceId,
      ipCountry: Storage.get('geoData').countyCode,
      userPackage,
      userType,
      episodeId,
      dateAired,
    }

    switch (behaviorType) {
      case 'COMPLETE':
      case 'PAUSE':
      case 'STOP':
        mediaLogData.duration = Math.round(time) - lastPosition
        mediaLogData.lastPosition = Math.round(time)
        break
      case 'RESUME':
      case 'PLAY':
      case 'SEEK':
        mediaLogData.startPosition = Math.round(time)
        break
      default: // 'BUFFER'
    }

    const result = await Post(path, mediaLogData, {})
    return result
  } catch (e) {
    throw e
  }
}
