// https://developer.samsung.com/SmartTV/develop/guides/user-interaction/keyboardime.html
export default {
  EXIT: 10009,
  BACK: 10009,
  ENTER: 13,
  DONE: 65376,
  CANCEL: 65385,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  PLAYPAUSE: 10252,
  PLAY: 415,
  PAUSE: 19,
  FORWARD: 417,
  BACKWARD: 412,
  STOP: 413,
  // NOTE: do we support next/prev track buttons?
  // NEXTTRACK: 10233,
  // PREVTRACK: 10232,
}
