import { Storage } from '../lib'
/* global ga: true */

export const googleAnalytics = {
  tracker: null,

  init: function () {
    const userData = Storage.get('login')
    const settings = Storage.get('settings')

    if (!userData || !settings) return
    ga('create', settings.gaTrackingId, 'auto')
    ga((trackerName) => (this.tracker = trackerName))
    const { userId, token } = userData
    let fields = {}
    if (token && token.userPackage) fields['dimension1'] = token.userPackage
    if (userId) fields['dimension4'] = userId
    if (userId) fields['dimension6'] = userId // this may need to be corrected

    ga('set', fields)
  },

  track: function (event, path) {
    let tfcEvent
    if (typeof event === 'string') {
      tfcEvent = {
        eventCategory: event,
        eventAction: event,
        eventLabel: path,
      }
    } else if (event) {
      tfcEvent = event
    } else return
    if (!this.tracker) this.init()
    const gaEvent = this.convertTFCEventToGoogleEvent(tfcEvent)
    ga('send', gaEvent)
  },

  convertTFCEventToGoogleEvent: function (tfcEvent) {
    // metric1:, // AMP VIEW DURATION
    // metric2:, // AMP MEDIA DURATION
    // dimension1: // TFC ONLINE USER STATUS (in init)
    // dimension2:, // AMP MEDIA TITLE
    // dimension3: N/A
    // dimension4: // TFC.tv User Id (in init)
    // dimension5: // Gigya Uid
    // dimension6: // Fingerprint Id (in init; confirm value)
    let googleEvent = {}

    Object.keys(tfcEvent).forEach((item) => {
      googleEvent[item] = tfcEvent[item]
    })

    // hit types include pageview, screenview, event, transaction, item, social, exception, and timing
    googleEvent.hitType = 'event'

    return googleEvent
  },
}
