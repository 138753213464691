import { Get } from '../lib'

const path = 'Site/GetSectionByPage'

export default async function(id) {
  try {
    const result = await Get(`${path}/${id}`)
    return result.featureSection || []
  } catch (e) {
    throw e
  }
}
