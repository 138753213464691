import { Get } from '../lib'

const path = 'Site/GetSearchResults'

export default async function(query) {
  try {
    const result = await Get(`${path}/${query}`)
    return result.searchResults
  } catch (e) {
    throw e
  }
}
