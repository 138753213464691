import { Get, Storage } from '../lib'

const path = 'Sync/GetEpisodesByPage'

export default async function (id) {
  try {
    const result = await Get(`${path}/${id}?plan=${Storage.get('login').token.userPackage}`)
    return {
      ...result.show,
    }
  } catch (e) {
    throw e
  }
}
