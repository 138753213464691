/**
 * Returns an array with arrays of the given size.
 *
 * @param arr {Array} array to split
 * @param size {Integer} Size of every group
 */
export default (arr, size) => {
  const res = []
  if (Array.isArray(arr)) {
    for (let i = 0; i < arr.length; i += size) {
      const chunk = arr.slice(i, i + size)
      res.push(chunk)
    }
  }

  return res
}
