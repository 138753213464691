// This is the only call that uses the base hostname.
// Using fetch directly to avoid adding exceptions to the request lib.
import { Request, Settings, Storage } from '../lib'

export default async function () {
  const host = Settings.apiBaseHostname
  const base = Settings.apiBasePath
  const path = 'Site/GetRokuSettings'
  const mode = process.env.REACT_APP_API_MODE_ID
  const result = await Request(`${host}${base}${path}/${mode}?devicetype=${Storage.get('deviceSettings').deviceType}`)
  return result
}
