import { Post } from '../lib'

const path = 'Media/hb'

export default async function () {
  try {
    const result = await Post(path)

    return result
  } catch (e) {
    throw e
  }
}
