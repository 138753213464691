import { Crypto, Post, Settings, Storage } from '../lib'

const path = 'User/SignInByEmail'

export default async function (email, password) {
  const expires = Math.round(Date.now() / 1000) + 300
  const data = `${email}|${password}|${expires}`
  const deviceId = Storage.get('deviceSettings').deviceId
  const clientId = deviceId
  const pucId = deviceId
  const deviceDescription = Storage.get('deviceSettings').deviceDescription
  const deviceType = Storage.get('deviceSettings').deviceType
  const encrypted = Crypto(data, Settings.apiCryptoKey, deviceId)

  try {
    const result = await Post(path, {
      email,
      clientId,
      deviceDescription,
      deviceId,
      deviceType,
      password: encrypted,
      pucId,
    })
    return result
  } catch (e) {
    throw e
  }
}
