// Convert to string and trim
const transport = (arg) => (!!arg ? String(arg).trim() : false)
// Array to string
const arrayToString = (arr) => arr.map(getClassNames).filter(Boolean).join(' ')
// Object to string
const objectToString = (obj) => {
  const valid = []
  Object.keys(obj).forEach((prop) => {
    if (!obj[prop]) return
    valid.push(prop)
  })

  return valid.length > 0 ? valid.join(' ').trim() : false
}
// Callback to string
const functionToString = (cb) => {
  try {
    return cb()
  } catch (error) {
    return false
  }
}

/**
 * Convert given arg [String, Number, Array, Object, Function] to classnames
 * @param {*} classname
 */
const getClassNames = (classname) => {
  if (!classname || classname === 'undefined') return false

  if (typeof classname === 'object') {
    if (Array.isArray(classname)) {
      return transport(arrayToString(classname))
    } else {
      return transport(objectToString(classname))
    }
  }

  if (typeof classname === 'function') {
    return transport(functionToString(classname))
  }

  return transport(classname)
}

export default (...args) => args.map(getClassNames).filter(Boolean).join(' ')
