import Uuid from './Uuid'

const ALLOWED_DEVICE_ID_LENGTH = 49
export default (id) => {
  let uuid = Uuid()
  let deviceId = id || uuid

  // remove special chars
  deviceId = deviceId.replace(/[^\w\s-]/gi, '')

  // -> null -> reset
  if (!deviceId) {
    deviceId = uuid
  }

  if (deviceId.length < ALLOWED_DEVICE_ID_LENGTH) {
    return deviceId
  }

  return deviceId.substr(deviceId.length - ALLOWED_DEVICE_ID_LENGTH) // Get last x chars; The API has x chars limit
}
