import { Get, Storage } from '../lib'

const path = 'Sync/GetSubCategories'

export default async function(id) {
  try {
    const params = `plan=${Storage.get('login').token.userPackage}&kbotoken=${Storage.get('login').kboToken}`
    const result = await Get(`${path}/${id || 3907}?${params}`)
    return result.categories
  } catch (e) {
    throw e
  }
}
