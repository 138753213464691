import { SignOutByDeviceId } from '../api'
import { googleAnalytics } from '../global/googleAnalytics'
import { Amplitude } from '../global/Amplitude'

export default (push) => {
  return async () => {
    Amplitude.logEvent('logOut')
    googleAnalytics.track('signOut', 'User/SignOutByDeviceId')
    await SignOutByDeviceId()
    Object.keys(localStorage).forEach((key) => {
      if (key === 'deviceSettings') return
      localStorage.removeItem(key)
    })
    push('/')
  }
}
