import env from '../env'

export default (event) => {
  let keyName
  try {
    const { Keys } = env.Platform
    keyName = Object.keys(Keys).find((i) => Keys[i] === event.keyCode)
  } catch (_) {}

  return keyName || event.key || event.code || 'Unkown'
}
