import { Get } from '../lib'

const path = 'User/CheckShowIfAllowed'

export default async function(id) {
  try {
    const result = await Get(`${path}?showid=${id}`)
    return result.showDetails
  } catch (e) {
    throw e
  }
}
