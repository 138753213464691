import { Crypto, Post, Settings, Storage } from '../lib'

const path = 'User/SignInByDeviceId'

export default async function (reset) {
  const expires = Math.round(Date.now() / 1000) + 300
  const deviceId = Storage.get('deviceSettings').deviceId
  const clientId = deviceId
  const pucId = deviceId
  const deviceDescription = Storage.get('deviceSettings').deviceDescription
  const deviceType = Storage.get('deviceSettings').deviceType
  const data = `${deviceId}|${clientId}|${expires}`
  const encrypted = Crypto(data, Settings.apiCryptoKey, deviceId)
  try {
    const result = await Post(path, {
      deviceId,
      clientId,
      data: encrypted,
      deviceDescription,
      deviceType,
      pucId,
      reset,
    })
    return result.loginResult
  } catch (e) {
    throw e
  }
}
