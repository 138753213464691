import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import 'url-polyfill'

export default () => {
  return new Promise((resolve) => {
    const setSettings = () => {
      let deviceInfo = {
        deviceType: 'smarttv',
      }

      try {
        deviceInfo.deviceDescription = window.webapis.productinfo.getModel()
        deviceInfo.deviceId = window.webapis.productinfo.getDuid()
      } catch (_) {
        deviceInfo.deviceDescription = 'Samsung Smart TV'
      }

      resolve(deviceInfo)
    }

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = '$WEBAPIS/webapis/webapis.js'
    script.onload = () => {
      console.log('Webapis loaded')
      setSettings()
    }
    script.onerror = () => {
      console.log('Loading webapis script failed')
      setSettings()
    }

    document.head.appendChild(script)
  })
}
