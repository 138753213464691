import { Get, Storage } from '../lib'

const path = 'User/RedeemKBOToken'

export default async function (id) {
  try {
    const result = await Get(`${path}?catid=${id}&DeviceType=${Storage.get('deviceSettings').deviceType}`)
    return result
  } catch (e) {
    throw e
  }
}
