import { Post } from '../lib'

const path = 'List/Delete'

export default async function (categoryId, episodeId, type) {
  try {
    const result = await Post(
      path,
      {
        categoryId,
        episodeId,
        type,
      },
      {},
    )

    return result
  } catch (e) {
    throw e
  }
}
