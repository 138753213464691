import amplitude from 'amplitude-js'

const API_KEY = process.env.REACT_APP_AMPLITUDE_KEY

// const API_KEY = 'd8fdd7ccc119a78d74e3ccb72b38fa94';   // stage
// const API_KEY = '1a2aac4ad050039f92083664afc721d3';   // productive

console.log('>>>> amplitude: ', amplitude)
export const Amplitude = {
  init: function () {
    amplitude.getInstance().init(API_KEY)
  },
  setUserId: function (uid) {
    amplitude.getInstance().setUserId(uid)
  },
  logEvent: function (event, parameters) {
    if (typeof parameters !== 'undefined' && typeof parameters === 'object') {
      console.log('>>>> Amplitude.logEvent(', event, parameters)
      amplitude.getInstance().logEvent(event, parameters)
    } else {
      console.log('>>>> Amplitude.logEvent(', event)
      amplitude.getInstance().logEvent(event)
    }
  },
  setUserProperties: function (properties) {
    console.log('>>>> Amplitude.setUserProperties(', properties)
    amplitude.getInstance().setUserProperties(properties)
  },
  setIdentify: function (properties) {
    let identify = new amplitude.Identify()
    if (typeof properties !== 'undefined' && typeof properties === 'object') {
      for (const key in properties) {
        console.log('>>>>  props: ', key, properties[key])
        identify.set(key, properties[key])
      }
    }
    amplitude.getInstance().identify(identify)
  },
  setDeviceId: function (did) {
    amplitude.getInstance().setDeviceId(did)
  },

  getDeviceId: function () {
    return amplitude.getInstance().getDeviceId()
  },
  getSessionId: function () {
    return amplitude.getInstance()._sessionId
  },
  loginSuccess: function (login) {
    console.log('>>>> Setting user attributes to Amplitude', login)
    amplitude.getInstance().setUserId(login.userId)
    console.log('>>>> Amplitude.setUserProperties(', {
      subscription: login.token.userPackage,
      regDate: login.token['.issued'],
      product: 'iwanttfc',
    })
    amplitude.getInstance().setUserProperties({
      subscription: login.token.userPackage,
      regDate: login.token['.issued'],
      product: 'iwanttfc',
    })
    console.log('>>>> Amplitude.logEvent(', { registrationType: 'Sign In By Device' })
    amplitude.getInstance().logEvent('loginSuccess', { registrationType: 'Sign In By Device' })
  },
}
