import { Crypto, Post, Settings, Storage } from '../lib'

const path = 'User/SignOutByDeviceId'

export default async function () {
  const expires = Math.round(Date.now() / 1000) + 300
  const deviceId = Storage.get('deviceSettings').deviceId
  const clientId = deviceId
  const data = `${deviceId}|${clientId}|${expires}`
  const encrypted = Crypto(data, Settings.apiCryptoKey, deviceId)
  try {
    const result = await Post(path, {
      deviceId,
      clientId,
      data: encrypted,
    })
    return result
  } catch (e) {
    throw e
  }
}
