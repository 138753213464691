import { Get } from '../lib'

const path = 'Site/GetHeroSlides'

export default async function(id) {
  try {
    const result = await Get(`${path}/${id || 0}`)
    return result.heroSection
  } catch (e) {
    throw e
  }
}
